<template>
    <div id="app" class="p-4 bg-gray-100">
        <h3 class="mb-2 font-bold text-lg">Pre Order Items</h3>
        <DxLoadPanel v-model:visible="isLoading" shading-color="rgba(0,0,0,0.4)" :show-indicator="showIndicator" :show-pane="showPane" :shading="shading" :close-on-outside-click="closeOnOutsideClick"/>
        <DxDataGrid :data-source="products" :remote-operations="false" :allow-column-reordering="true" :row-alternation-enabled="true" :show-borders="true" :allow-column-resizing="true" @row-updated="rowUpdated" id="dataGrid">
            <DxEditing :allow-updating="true" :allow-adding="false" :allow-deleting="false" mode="form"/>
            <DxFilterRow :visible="true" />
            <DxColumn data-field="sku" caption="SKU" width="200" :allow-editing="false"/>
            <DxColumn data-field="name" caption="Title" :allow-editing="false"/>
            <DxColumn data-field="level" data-type="number" caption="LW Stock Level" width="150" :allow-editing="false"/>
            <DxColumn data-field="preorder_release_date" caption="Pre Order Date" data-type="date" format="yyyy-MM-dd" width="150"/>
            <DxColumn data-field="preorder_message" caption="Pre Order Message">
                <DxFormItem :col-span="2" :editor-options="{ height: 100 }" editor-type="dxTextArea"/>
            </DxColumn>
            <DxColumn data-field="is_preorder_only" caption="Remove After Date" data-type="boolean" width="150"/>
        </DxDataGrid>
    </div>
</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxEditing,
    DxFormItem
} from 'devextreme-vue/data-grid';
import 'devextreme-vue/text-area';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import axios from 'axios'
import moment from 'moment'
export default {
    name: 'App',
    components: {
        DxDataGrid,
        DxColumn,
        DxFilterRow,
        DxEditing,
        DxFormItem,
        DxLoadPanel
    },
    data() {
        return {
            products: [],
            isLoading: false,
            position: { of: '#dataGrid' },
            showIndicator: true,
            shading: true,
            showPane: true,
            closeOnOutsideClick: false,
        }
    },
    mounted() {
        this.GetData();
    },
    methods: {
        GetData() {
            this.isLoading = true;
            axios({
                "method": "GET",
                "url": "https://api.darkside-developments.com/api/bigcommerce/preorder"
            }).then((resp) => {
                console.log(resp.data)
                this.products = resp.data;
                this.isLoading = false;
            }).catch(err => {
                console.log(err)
                this.isLoading = false;
            })
        },
        rowUpdated(value) {
            this.isLoading = true;
            const p_msg = value.key.preorder_message;
            const p_date = value.key.preorder_release_date
            const p_preorder_only = value.key.is_preorder_only
            const p_id = value.key.id
            axios.post("https://api.darkside-developments.com/api/bigcommerce/preorder/update", {
                id: p_id,
                date: p_date,
                msg: p_msg,
                preorder_only: p_preorder_only
            }).then(resp => {
                console.log(resp.data)
                if(resp.data.success) {
                    this.$message.info('Updated');
                } else {
                    this.$message.error(resp.data.message.title ?? "Failed");
                }
                this.isLoading = false;
            }).catch(err => {
                console.log(err)
                this.isLoading = false;
            })
        }
    },
    filters: {
        date(val) {
            return moment(val).format('YYYY-MM-DD')
        }
    }
}
</script>

<style lang="scss">
</style>
